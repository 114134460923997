import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Link from "../components/link"
import Pagination from "../components/pagination"
import { htmlDecode } from "../Utils"

const BlogTagArchiveTemplate = ({ data, pageContext }) => {
  const seoTitle = (data.strapiBlogArchive.seo) ? data.strapiBlogArchive.seo.seoTitle : data.strapiBlogArchive.title
  const seoDesc = (data.strapiBlogArchive.seo) ? data.strapiBlogArchive.seo.seoDescription : ''
  const featuredImageUrl = (data.strapiBlogArchive.featuredImage?.formats?.large) ? data.strapiBlogArchive.featuredImage.formats.large.url : data.strapiBlogArchive.featuredImage.url
  const featuredContent = htmlDecode(data.featuredPost.content)
  const cdnUrl = 'https://dlyog079eaher.cloudfront.net';

  useEffect(() => {
    if (window && window.jQuery) {
      window.jQuery(document).ready(() => {
        window.jQuery('#tagDropdownMenuButton').on('click', function (e) {
          e.preventDefault();
          window.jQuery('.tagDropdownMenuButton').toggle();
        });
      });
    }
  });

  return (
    <Layout classNames="blog page-template-tpl-blog-archive page-template-tpl-blog-archive-php">
      <Seo title={seoTitle} seoTitle={seoTitle} seoDescription={seoDesc} />
      <section className="banner-wrap">
        <div id="banner" className="banner">
          <div className="col-12 banner-bg-image ph0 small gradient-map" style={{ backgroundImage: `url(${featuredImageUrl.includes(cdnUrl) ? featuredImageUrl : cdnUrl + featuredImageUrl})`, opacity: 1 }}>
          </div>
          <div className="banner-overlay blue-overlay" />
          <div className="banner-inside aic jcfs" style={{ height: '300px' }}>
            <div className="col-12 banner-text text-center blue-overlay">
              <h1 className>{pageContext.name}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content-wrap">
        <div className="container" style={{ zIndex: 101 }}>
          {data.featuredPost &&
            <div className="subsearch sub9 only-xs-ph0" style={{ marginTop: '-26rem' }}>
              <div className="row align-items-center">
                <div className="col-12 featured-post">
                  {(data.featuredPost?.categories && data.featuredPost?.categories.length > 0) &&
                    <>
                      {data.featuredPost.categories.map((category, index) => (
                        <a className="cat-btn mt1 mr1" href={`/blog/category/${category.slug}`}>
                          {category.name}
                        </a>
                      ))}
                    </>
                  }
                  {data.featuredPost.title &&
                    <h2 className="module-header mt3">{htmlDecode(data.featuredPost.title)}</h2>
                  }
                  <hr className="small orange left mv2" />
                  <div className="content copy col-12 ph0 mb1.5">{featuredContent.substring(0, 400)}&hellip;</div>
                  <div className="read-more-author-bar col-12 ph0 dif aic">
                    <div className="button-wrap col-8 ph0">
                      <Link className="button uppercase" href={`/blog/${data.featuredPost.slug}`}>Read Now</Link>
                    </div>
                    {(data.featuredPost?.authors && data.featuredPost?.authors.length > 0) &&
                      <div className="author-wrap col-8 ph0">
                        <span className="author">By {data.featuredPost.authors[0].firstName} {data.featuredPost.authors[0].lastName}</span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          <div className="categories ph.8 pv.8">
            <div className="row">
              <div className="col-12 dif fww cat-tag ph0 only-xs-ph.5 jcsb aic">
                {(pageContext?.categories?.edges && pageContext?.categories?.edges.length > 0) &&
                  <div className="cat-list d-none d-lg-flex ph0">
                    <ul className="nav" style={{ whiteSpace: 'nowrap' }}>
                      <li className="nav-item">
                        <Link className="nav-link ft5" href="/blog/">All</Link>
                      </li>
                      {pageContext.categories.edges.map((category) => (
                        <li className="nav-item ml0">
                          <Link className="nav-link ft5" href={`/blog/category/${category.node.slug}`}>{category.node.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                }
                {(pageContext?.categories?.edges && pageContext?.categories?.edges.length > 0) &&
                  <div className="cat-list-drop-wrap d-lg-none ph0 mt0 mb0 xl-mb1">
                    <div className="dropdown tag-drop">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Posts by Categories
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {pageContext.categories.edges.map((category) => (
                          <li className="nav-item">
                            <Link className="dropdown-item" href={`/blog/category/${category.node.slug}`}>{category.node.name}</Link>
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                }
                {(pageContext?.tags?.edges && pageContext?.tags?.edges.length > 0) &&
                  <div className="tag-list-drop-wrap ph0 tar mt0 mb0 xl-mb1">
                    <div className="dropdown tag-drop">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="tagDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Posts by Tag
                      </button>
                      <div className="dropdown-menu tagDropdownMenuButton" aria-labelledby="dropdownMenuButton">
                        {pageContext.tags.edges.map((tag) => (
                          <li className="nav-item">
                            <Link className="dropdown-item" href={`/blog/tag/${tag.node.slug}`}>{tag.node.name}</Link>
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="blog-content-wrap col-12 ph0 dif fww">
              <div className="blog-posts col-12 col-md-8 ph0 only-xs-ph1">
                <div className="tease-items-block pv6">
                  <div className="container">
                    <div className="row jcc">
                      {data.allStrapiPost.edges.map(({ node }) => (
                        <div className="col-lg-6 list-item pr4 only-xs-ph0">
                          <div className="list-content bgw br3 mb4 ofh bs1">
                            {node.featuredImage &&
                              <Link href={`/blog/${node.slug}`} title={htmlDecode(node.title)} className="dbha">
                                {node.featuredImage?.formats?.medium &&
                                  <div className="list-content_image-wrap brdr1--bottom bcg3" style={{ backgroundImage: `url(${node.featuredImage.formats.medium.url.includes(cdnUrl) ? node.featuredImage.formats.medium.url : cdnUrl + node.featuredImage.formats.medium.url})` }} />
                                }
                                {!node.featuredImage?.formats?.medium &&
                                  <div className="list-content_image-wrap brdr1--bottom bcg3" style={{ backgroundImage: `url(${node.featuredImage.url.includes(cdnUrl) ? node.featuredImage.url : cdnUrl + node.featuredImage.url})` }} />
                                }
                              </Link>
                            }
                            <div className="teaser-text-wrap">
                              {(node.categories && node.categories.length > 0) &&
                                <>
                                  {node.categories.map((category, index) => (
                                    <a className="cat-btn mt.5 mr1" href={`/blog/category/${category.slug}`}>
                                      {category.name}
                                    </a>
                                  ))}
                                </>
                              }
                              <Link href={`/blog/${node.slug}`}>
                                <h3 className="teaser-header mt2">{htmlDecode(node.title)}</h3>
                              </Link>
                              <div className="read-more-author-bar">
                                <div className="button-wrap">
                                  <Link className="button uppercase" href={`/blog/${node.slug}`}>Read Now</Link>
                                </div>
                                {(node.authors && node.authors.length > 0) &&
                                  <div className="author-wrap">
                                    <span className="author">By {node.authors[0].firstName} {node.authors[0].lastName}</span>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-sidebar col-12 col-md-4 ph1 only-xs-ph0">
                <Link className="button uppercase" href="/subscribe/" style={{ margin: '18px 0 30px' }}>Subscribe to Our Blog</Link>
                <ul className="nav nav-tabs" role="tablist">
                  {(pageContext.popularPosts.edges.length > 0) &&
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#popular" role="tab">Popular</a>
                    </li>
                  }
                  {(pageContext.recentPosts.edges.length > 0) &&
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#recent" role="tab">Recent</a>
                    </li>
                  }
                </ul>
                <div className="tab-content">
                  {(pageContext.popularPosts.edges.length > 0) &&
                    <div className="tab-pane fade show active" id="popular" role="tabpanel">
                      {pageContext.popularPosts.edges.map((popular, index) => (
                        <div className="list-item col-12 brdr1--bottom bcg3 pv2.5 ph0">
                          {popular.node.featuredImage &&
                            <div className="col-12 col-lg-6 pl0 only-xs-ph0">
                              <div className="list-content_image-wrap--sb">
                                <Link href={`/blog/${popular.node.slug}`} title={htmlDecode(popular.node.title)}>
                                  {popular.node.featuredImage?.formats?.medium &&
                                    <img alt={htmlDecode(popular.node.title)} className="dbha" src={`${popular.node.featuredImage.formats.medium.url.includes(cdnUrl) ? popular.node.featuredImage.formats.medium.url : cdnUrl + popular.node.featuredImage.formats.medium.url}`} />
                                  }
                                  {!popular.node.featuredImage?.formats?.medium &&
                                    <img alt={htmlDecode(popular.node.title)} className="dbha" src={`${popular.node.featuredImage.url.includes(cdnUrl) ? popular.node.featuredImage.url : cdnUrl + popular.node.featuredImage.url}`} />
                                  }
                                </Link>
                              </div>
                            </div>
                          }
                          <div className="col-12 col-lg-6 preview pr0 only-xs-ph0">
                            {(popular.node.categories && popular.node.categories.length > 0) &&
                              <>
                                {popular.node.categories.map((category, index) => (
                                  <a className="cat-btn mb1 mr1" href={`/blog/category/${category.slug}`}>
                                    {category.name}
                                  </a>
                                ))}
                              </>
                            }
                            <Link href={`/blog/${popular.node.slug}`}>
                              <h3 className="teaser-subhead">{htmlDecode(popular.node.title)}</h3>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                  {(pageContext.recentPosts.edges.length > 0) &&
                    <div className="tab-pane" id="recent" role="tabpanel">
                      {pageContext.recentPosts.edges.map((recent, index) => (
                        <div className="list-item col-12 brdr1--bottom bcg3 pv2.5 ph0">
                          {recent.node.featuredImage &&
                            <div className="col-12 col-lg-6 pl0 only-xs-ph0">
                              <div className="list-content_image-wrap--sb">
                                <Link href={`/blog/${recent.node.slug}`} title={htmlDecode(recent.node.title)}>
                                  {recent.node.featuredImage?.formats?.medium &&
                                    <img alt={htmlDecode(recent.node.title)} className="dbha" src={`${recent.node.featuredImage.formats.medium.url.includes(cdnUrl) ? recent.node.featuredImage.formats.medium.url : cdnUrl + recent.node.featuredImage.formats.medium.url}`} />
                                  }
                                  {!recent.node.featuredImage?.formats?.medium &&
                                    <img alt={htmlDecode(recent.node.title)} className="dbha" src={`${recent.node.featuredImage.url.includes(cdnUrl) ? recent.node.featuredImage.url : cdnUrl + recent.node.featuredImage.url}`} />
                                  }
                                </Link>
                              </div>
                            </div>
                          }
                          <div className="col-12 col-lg-6 preview pr0 only-xs-ph0">
                            {(recent.node.categories && recent.node.categories.length > 0) &&
                              <>
                                {recent.node.categories.map((category, index) => (
                                  <a className="cat-btn mb1 mr1" href={`/blog/category/${category.slug}`}>
                                    {category.name}
                                  </a>
                                ))}
                              </>
                            }
                            <Link href={`/blog/${recent.node.slug}`}>
                              <h3 className="teaser-subhead">{htmlDecode(recent.node.title)}</h3>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {pageContext.totalPages > 1 &&
          <Pagination currentPage={pageContext.currentPage} totalPages={pageContext.totalPages} slug={`blog/tag/${pageContext.slug}`} />
        }
      </section>
    </Layout>
  )
}

export default BlogTagArchiveTemplate

export const query = graphql`
  query BlogTagArchiveTemplate($featuredPost: String!, $slug: String!, $limit: Int!, $skip: Int!) {
    strapiBlogArchive {
      seo {
        excludeFromSitemap
        noFollow
        noIndex
        seoTitle
        seoDescription
      }
      title
      featuredImage {
        url
        formats {
          large {
            url
          }
        }
      }
    }
    featuredPost: strapiPost(id: {eq: $featuredPost}) {
      slug
      title
      content
      authors {
        firstName
        lastName
      }
      categories {
        name
      }
    }
    allStrapiPost(
      limit: $limit,
      skip: $skip,
      sort: {fields: createdAt, order: DESC}
      filter: {tags: {elemMatch: {slug: {eq: $slug}}}, showInArchive: {ne: false}}
    ) {
      edges {
        node {
          slug
          title
          featuredImage {
            url
            formats {
              medium {
                url
              }
            }
          }
          authors {
            firstName
            lastName
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`
